import React, { SyntheticEvent, useState } from 'react';
import { Box, Stack, Tabs, Tab, Typography } from '@mui/material';
import VideoEditTable from '../../../../components/channel/[channelId]/video/content/VideoEditTable';
import { useParams } from 'react-router-dom';
import { TabPanel } from 'ui';

const Content: React.FC = () => {
    const { username } = useParams();
    if (username === undefined) {
        return <div>Channel Not Found</div>;
    }
    const [tabIndex, setTabIndex] = useState<number>(0);
    const handleChange = (event: SyntheticEvent, newIndex: number) => {
        setTabIndex(newIndex);
    };
    return (
        <Stack sx={{ paddingTop: '40px' }}>
            <Typography variant="h5" gutterBottom component="div">
                Channel Content
            </Typography>
            <Box>
                <Tabs
                    value={tabIndex}
                    onChange={handleChange}
                    aria-label="content navigation"
                >
                    <Tab label="videos" id="videos" aria-controls="videos" />
                    <Tab
                        label="processing"
                        id="processing"
                        aria-controls="processing"
                    />
                </Tabs>
            </Box>
            <TabPanel tabIndex={0} currentIndex={tabIndex}>
                <VideoEditTable
                    order="desc"
                    orderBy="Video"
                    channelId={username}
                />
            </TabPanel>
        </Stack>
    );
};
export default Content;
