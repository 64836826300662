import React from 'react';
import { useAccountContext } from 'hooks';
import { Stack, Typography } from '@mui/material';
import ChannelStatistics from '../../../../components/channel/[channelId]/video/dashboard/channel/ChannelStatistics';

const Dashboard: React.FC = () => {
    const { userInfo } = useAccountContext();
    if (!userInfo) {
        return <div>User not logged in</div>;
    }
    return (
        <Stack sx={{ paddingTop: '40px' }}>
            <Typography variant="h5" gutterBottom component="div">
                Channel Dashboard
            </Typography>
            <ChannelStatistics username={userInfo.username} />
        </Stack>
    );
};

export default Dashboard;
