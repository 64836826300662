import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Customization from './editing/Customization';
import TierEditPage from './subscriptions/tiers';
import Content from './video/Content';
import Upload from './video/upload';
import Dashboard from './video/Dashboard';
import Analytics from './analytics/Analytics';
import VideoComments from './video/[videoId]/comments/VideoComments';
import Comments from './video/Comments';

const ChannelRouter: React.FC = () => {
    return (
        <Routes>
            <Route path=":username/videos/dashboard" element={<Dashboard />} />
            <Route path=":username/videos/upload" element={<Upload />} />
            <Route path=":username/videos/content" element={<Content />} />
            <Route
                path=":username/videos/content/:videoId/comments"
                element={<VideoComments />}
            />
            <Route
                path=":username/videos/content/comments"
                element={<VideoComments />}
            />
            <Route path=":username/editing" element={<Customization />} />
            <Route path=":username/analytics/*" element={<Analytics />} />
            <Route path=":username/comments" element={<Comments />} />
            <Route path=":username/subscriptions" element={<Customization />} />
            <Route
                path=":username/subscriptions/tiers"
                element={<TierEditPage />}
            />
        </Routes>
    );
};

export default ChannelRouter;
