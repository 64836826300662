import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import { Box, Stack, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DailyPromotionHeader from '../../../../components/channel/[channelId]/video/dashboard/promotions/DailyPromotionsHeader';
import AdExpenseStatistics from '../../../../components/channel/[channelId]/video/dashboard/ads/AdExpenseStatistics';
import AdRevenue from '../../../../components/channel/[channelId]/video/dashboard/ads/AdRevenue';
import { useAccountContext } from 'hooks';
import ChannelStatistics from '../../../../components/channel/[channelId]/video/dashboard/channel/ChannelStatistics';
import { TimeFilter } from '../../../../components/channel/[channelId]/video/dashboard/MetricsChart.types';
import MetricsTimeFilterDropdown from '../../../../components/channel/[channelId]/video/dashboard/MetricsTimeFilterDropdown';

const Analytics: React.FC = () => {
    const { userInfo } = useAccountContext();
    const [timeFilter, setTimeFilter] = useState<TimeFilter>('Last 7 Days');
    const [tabIndex, setTabIndex] = useState<string>('1');
    const handleTabChange = (
        _event: React.SyntheticEvent,
        newTabIndex: string,
    ) => {
        setTabIndex(newTabIndex);
    };
    return (
        <Stack sx={{ pt: 4 }} spacing={2}>
            <Typography variant="h4">Channel analytics</Typography>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabIndex}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={handleTabChange}
                            aria-label="Analytics Tab Section"
                        >
                            <Tab label="Overview" value="1" />
                            <Tab label="Ad Revenue" value="2" />
                            <Tab label="Ad Expense" value="3" />
                            <Tab label="Promotions" value="4" />
                            <Stack alignItems="flex-end" width="100%">
                                <MetricsTimeFilterDropdown
                                    timeFilter={timeFilter}
                                    setTimeFilter={setTimeFilter}
                                />
                            </Stack>
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <ChannelStatistics
                            username={userInfo?.username ?? ''}
                        />
                    </TabPanel>
                    <TabPanel value="2">
                        <AdRevenue timeFilter={timeFilter} />
                    </TabPanel>
                    <TabPanel value="3">
                        <AdExpenseStatistics timeFilter={timeFilter} />
                    </TabPanel>
                    <TabPanel value="4">
                        <DailyPromotionHeader timeFilter={timeFilter} />
                    </TabPanel>
                </TabContext>
            </Box>
        </Stack>
    );
};
export default Analytics;
