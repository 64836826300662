import React, { createContext, useContext, useState } from 'react';
import { Keplr } from '@keplr-wallet/types';
import { OfflineSigner } from '@cosmjs/proto-signing';
import { SigningCosmWasmClient } from '@cosmjs/cosmwasm-stargate';
type WalletContextType = {
    cosmosWalletAddress: string;
    keplrWalletAddress: string | null;
    connectKeplrWallet: () => Promise<void>;
    connectCosmosWallet: () => Promise<void>;
    disconnectKeplrWallet: () => Promise<void>;
    disconnectCosmosWallet: () => Promise<void>;
};

export const WalletContext = createContext<WalletContextType>({
    cosmosWalletAddress: '',
    keplrWalletAddress: '',
    connectKeplrWallet: () => {
        return new Promise(() => {
            console.log('connectWallet');
        });
    },
    connectCosmosWallet: () => {
        return new Promise(() => {
            console.log('connectCosmosWallet');
        });
    },
    disconnectCosmosWallet: () => {
        return new Promise(() => {
            console.log('disconnectCosmosWallet');
        });
    },
    disconnectKeplrWallet: () => {
        return new Promise(() => {
            console.log('disconnectKeplrWallet');
        });
    },
});

interface WalletProviderProps {
    cosmostationClient: CosmostationClient;
    keplrClient: KeplrClient;
}

export const WalletProvider: React.FC<
    React.PropsWithChildren<WalletProviderProps>
> = (props) => {
    const [keplrWalletAddress, setKeplrWalletAddress] = useState<string | null>(null);
    const [cosmosWalletAddress, setCosmosWalletAddress] = useState<string>('');
    const connectKeplrAccount = async () => {
        const keplrAccounts = await props.keplrClient.getAccounts();
        if (!keplrAccounts || !keplrAccounts[0]) {
            console.error('No keplr wallets found');
        }
        setKeplrWalletAddress(keplrAccounts?.[0]?.address ?? null);
    };
    const connectCosmosAccount = async () => {
        const cosmosAccounts = await props.cosmostationClient.getAccounts();
        setCosmosWalletAddress(cosmosAccounts?.[0]?.address ?? '');
    };
    const disconnectKeplrWallet = async () => {
        setKeplrWalletAddress(null);
    };
    const disconnectCosmosWallet = async () => {
        setCosmosWalletAddress('');
    };

    return (
        <WalletContext.Provider
            value={{
                cosmosWalletAddress: cosmosWalletAddress,
                keplrWalletAddress: keplrWalletAddress,
                connectKeplrWallet: connectKeplrAccount,
                connectCosmosWallet: connectCosmosAccount,
                disconnectCosmosWallet: disconnectCosmosWallet,
                disconnectKeplrWallet: disconnectKeplrWallet,
            }}
        >
            {props.children}
        </WalletContext.Provider>
    );
};

export function useWalletContext() {
    return useContext(WalletContext);
}

export class KeplrClient {
    private _offlineSigner?: OfflineSigner = undefined;
    private _client?: SigningCosmWasmClient = undefined;
    constructor(chainId: string, rpcEndpoint: string, keplr?: Keplr) {
        keplr?.enable(chainId).then(() => {
            this._offlineSigner = keplr.getOfflineSigner(chainId);
            SigningCosmWasmClient.connectWithSigner(
                rpcEndpoint,
                this._offlineSigner,
            ).then((signer) => (this._client = signer));
        });
    }

    public async getAccounts() {
        return (await this._offlineSigner?.getAccounts()) ?? [];
    }
}

export class CosmostationClient {
    private _offlineSigner?: OfflineSigner = undefined;
    private _client?: SigningCosmWasmClient = undefined;
    constructor(chainId: string, rpcEndpoint: string, cosmostation?: Keplr) {
        cosmostation?.enable(chainId).then(() => {
            this._offlineSigner = cosmostation.getOfflineSigner(chainId);
            SigningCosmWasmClient.connectWithSigner(
                rpcEndpoint,
                this._offlineSigner,
            ).then((signer) => (this._client = signer));
        });
    }

    public async getAccounts() {
        return (await this._offlineSigner?.getAccounts()) ?? [];
    }
}
