import React, { useState } from 'react';
import {
    Box,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import ChannelViewCommentsTableSkeleton from './ChannelViewCommentsTableSkeleton';
import { getCommentsForChannel, GetCommentsForChannelResponse } from 'apis';
import { useQuery } from '@tanstack/react-query';
import { formatISOStringAsDDMMYYYY } from 'ui';
import CommentEditDropdown from './CommentEditDropdown';
import { traceSpan } from '../../../../../telemetry/tracing';
import TablePagination from '@mui/material/TablePagination';
import { PaginatedTable } from 'ui';

interface Data {
    // Comment: string;
    VideoTitle: string;
    Username: string;
    CreatedOn: Date;
    CommentBody: string;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headers: HeadCell[] = [
    // {
    //     id: 'Comment',
    //     label: 'Select',
    //     numeric: false,
    //     disablePadding: true,
    // },
    {
        id: 'Username',
        label: 'User',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'VideoTitle',
        label: 'Video Title',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'CreatedOn',
        label: 'Date',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'CommentBody',
        label: 'Comments',
        numeric: false,
        disablePadding: true,
    },
];
type Order = 'asc' | 'desc';
interface ChannelViewCommentsTableHeadProps {
    orderBy: keyof Data;
    order: Order;
}

const ChannelViewCommentsPageTableHeader: React.FC<
    ChannelViewCommentsTableHeadProps
> = (props) => {
    return (
        <TableHead>
            <TableRow>
                {headers.map((header) => (
                    <TableCell
                        key={header.id}
                        align={header.numeric ? 'right' : 'left'}
                        padding={header.disablePadding ? 'none' : 'normal'}
                        sortDirection={
                            props.orderBy === header.id ? props.order : false
                        }
                    >
                        <TableSortLabel
                            active={props.orderBy === header.id}
                            direction={
                                props.orderBy === header.id
                                    ? props.order
                                    : 'asc'
                            }
                        >
                            {header.label}
                            <Box component="span" sx={visuallyHidden}>
                                {props.order === 'desc'
                                    ? 'sorted descending'
                                    : 'sorted ascending'}
                            </Box>
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const CommentsTableBody: React.FC<{ data: GetCommentsForChannelResponse }> = (
    props,
) => {
    return (
        <TableBody>
            {props.data.map((comment) => (
                <TableRow key={comment._id}>
                    {/*<TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    inputProps={{
                                                        'aria-labelledby':
                                                            comment._id,
                                                    }}
                                                />
                                            </TableCell>
                                            */}
                    <TableCell
                        component="th"
                        id={comment._id}
                        scope="row"
                        padding="none"
                    >
                        <a
                            href={`https://www.bluechip.link/channel/${comment.username}/home`}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {comment.username}
                        </a>
                    </TableCell>
                    <TableCell
                        component="th"
                        id={comment._id}
                        scope="row"
                        padding="none"
                    >
                        <a
                            href={`https://www.bluechip.link/watch?v=${comment.parentId}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {comment.parentId}
                        </a>
                    </TableCell>
                    <TableCell
                        component="th"
                        id={comment._id}
                        scope="row"
                        padding="none"
                    >
                        {new Date(comment.createDate).toISOString()}
                    </TableCell>
                    <TableCell
                        component="th"
                        id={comment._id}
                        scope="row"
                        padding="none"
                    >
                        {comment.body}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
};

const ChannelViewCommentsTable: React.FC = () => {
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const handleChangePage = (
        _event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setLimit(parseInt(event.target.value, 10));
        setPage(0);
    };
    const commentsForChannelQuery = useQuery<GetCommentsForChannelResponse>(
        ['get-comments-for-channel', page, limit],
        () =>
            traceSpan('get-comments-for-channel', async () =>
                getCommentsForChannel({
                    page: page,
                    limit: limit,
                }),
            ),
        {
            refetchInterval: Infinity,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        },
    );
    return (
        <PaginatedTable
            isLoading={commentsForChannelQuery.isLoading}
            isError={commentsForChannelQuery.isError}
            data={commentsForChannelQuery.data ?? []}
            header={() => (
                <ChannelViewCommentsPageTableHeader
                    orderBy={'VideoTitle'}
                    order={'asc'}
                />
            )}
            body={() => (
                <CommentsTableBody data={commentsForChannelQuery.data ?? []} />
            )}
            loadingSkeleton={ChannelViewCommentsTableSkeleton}
            limit={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
};

const ChannelViewCommentsTable2: React.FC = () => {
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const handleChangePage = (
        _event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setLimit(parseInt(event.target.value, 10));
        setPage(0);
    };
    const commentsForChannelQuery = useQuery<GetCommentsForChannelResponse>(
        ['get-comments-for-channel', page, limit],
        () =>
            traceSpan('get-comments-for-channel', async () =>
                getCommentsForChannel({
                    page: page,
                    limit: limit,
                }),
            ),
        {
            refetchInterval: Infinity,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        },
    );
    return (
        <Stack>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                    <Table>
                        <ChannelViewCommentsPageTableHeader
                            orderBy={'VideoTitle'}
                            order={'asc'}
                        />
                        <ChannelViewCommentsTableSkeleton
                            isLoading={commentsForChannelQuery.isLoading}
                            isError={commentsForChannelQuery.isError}
                        >
                            <TableBody>
                                {(commentsForChannelQuery.data ?? [])?.map(
                                    (comment) => (
                                        <TableRow key={comment._id}>
                                            {/*<TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    inputProps={{
                                                        'aria-labelledby':
                                                            comment._id,
                                                    }}
                                                />
                                            </TableCell>
                                            */}
                                            <TableCell
                                                component="th"
                                                id={comment._id}
                                                scope="row"
                                                padding="none"
                                            >
                                                <a
                                                    href={`https://www.bluechip.link/channel/${comment.username}/home`}
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    {comment.username}
                                                </a>
                                                <CommentEditDropdown
                                                    parentId={comment.parentId}
                                                    commentId={comment._id}
                                                    body={comment.body}
                                                    username={comment.username}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={comment._id}
                                                scope="row"
                                                padding="none"
                                            >
                                                <a
                                                    href={`https://www.bluechip.link/watch?v=${comment.parentId}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {comment.parentId}
                                                </a>
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={comment._id}
                                                scope="row"
                                                padding="none"
                                            >
                                                {formatISOStringAsDDMMYYYY(
                                                    new Date(
                                                        comment.createDate,
                                                    ),
                                                )}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={comment._id}
                                                scope="row"
                                                padding="none"
                                            >
                                                {comment.body}
                                            </TableCell>
                                        </TableRow>
                                    ),
                                )}
                            </TableBody>
                        </ChannelViewCommentsTableSkeleton>
                    </Table>
                </TableContainer>
            </Paper>
            <Paper>
                <TablePagination
                    component="div"
                    count={limit}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Stack>
    );
};

export default ChannelViewCommentsTable;
