import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { FileSelectorComponent } from 'ui';
import LoadingButton from '@mui/lab/LoadingButton';
interface ImagesProps {
    username: string;
    uploadImages: (avatarFile?: File, bannerFile?: File) => void;
    submitting: boolean;
    avatarThumbnail?: string;
    bannerThumbnail?: string;
}

const Images: React.FC<ImagesProps> = (props) => {
    const [avatarFile, setAvatarFile] = useState<File | undefined>(undefined);
    const [bannerFile, setBannerFile] = useState<File | undefined>(undefined);
    return (
        <Stack spacing={2}>
            <Typography variant="h6" gutterBottom component="div">
                Picture
                <Typography variant="subtitle2" gutterBottom component="div">
                    Your profile picture appears on all of your content
                    thumbnails and channel page.
                </Typography>
            </Typography>
            <Stack direction="row" spacing={2}>
                <Box sx={{ width: '25%', height: '25%' }}>
                    {props.avatarThumbnail && (
                        <img
                            src={`/images/channel/${props.username}/avatar/${
                                props.avatarThumbnail ?? ''
                            }`}
                            style={{ width: '100%', height: '100%' }}
                        />
                    )}
                </Box>
                <FileSelectorComponent
                    fileType="image/*"
                    id="avatar thubmnail"
                    width="25%"
                    height="25%"
                    title="Upload"
                    setCurrentFile={setAvatarFile}
                    disabled={false}
                />
            </Stack>
            <Typography variant="h6" gutterBottom component="div">
                Banner Image
                <Typography variant="subtitle2" gutterBottom component="div">
                    Appears only in your main channel.
                </Typography>
            </Typography>
            <Stack direction="row" spacing={2}>
                <Box sx={{ width: '25%', height: '25%' }}>
                    {props.bannerThumbnail && (
                        <img
                            src={`/images/channel/${props.username}/banner/${
                                props.bannerThumbnail ?? ''
                            }`}
                            style={{ width: '100%', height: '100%' }}
                        />
                    )}
                </Box>
                <FileSelectorComponent
                    fileType="image/*"
                    id="banner thubmnail"
                    width="25%"
                    height="25%"
                    title="Upload"
                    setCurrentFile={setBannerFile}
                    disabled={false}
                />
            </Stack>
            <LoadingButton
                onClick={() => props.uploadImages(avatarFile, bannerFile)}
                type="submit"
                loading={props.submitting}
                loadingPosition="start"
                variant={props.submitting ? 'outlined' : 'contained'}
                sx={{ mt: 3, mb: 2, width: '10%' }}
            >
                Upload
            </LoadingButton>
        </Stack>
    );
};

export default Images;
